.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
    background: var(--bg);
    color: var(--ff);
    font-weight: 400;
    font-size: 15px;
    margin-top: 1rem;
    box-shadow: 0px -4px 6px var(--gg);
}

.footer {
    padding: 1rem 0;
}

.footer a {
    color: var(--cc);
}

@media (max-width: 1023px) {
    .footer {
        font-size: 12px;
    }
}
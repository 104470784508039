:root {
  --bg: #292824;
  --cl: #eee;
  --ff: #fff;
  --cc: #ccc;
  --gg: #565a75;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: var(--cl);
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
  border-radius: 20px;
}

img,
video {
  width: 100%;
}

@font-face {
  font-family: 'ExpoArabicBook';
  src: url("./components/font/ExpoArabicBook.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  overflow-x: hidden;
  font-family: 'ExpoArabicBook', sans-serif;
  max-width: 1430px;
  margin: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* hidden_all_items */
.hidden_all_items {
  transform: scale(0);
}

.show_all_items {
  animation: show-all_items 1s ease-in-out;
}

@keyframes show-all_items {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

/* END hidden_all_items */

select option:first-child {
  display: none !important;
}

.direction {
  overflow-x: hidden;
  min-height: 50vh;
}


/* //////////////// */
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 25rem;
  box-shadow: 0 0 5px var(--bg);
  padding: 20px;
  border-radius: 20px;
  position: relative;
  margin: 10% auto;
}

.title {
  font-size: 28px;
  color: var(--bg);
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  direction: rtl;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 0px;
  background-color: var(--bg);
}

.title::before {
  width: 18px;
  height: 18px;
  background-color: var(--bg);
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin a {
  color: var(--bg);
}

.signin a:hover {
  text-decoration: underline var(--bg);
}

.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.form label {
  position: relative;
}

.form label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form label span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: var(--gg);
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown+span {
  top: 15px;
  font-size: 0.9em;
}

.form label .input:focus+span,
.form label .input:valid+span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.form label .input:valid+span {
  color: var(--bg);
}

.submit {
  background-color: var(--bg);
  padding: 10px;
  border-radius: 10px;
  color: var(--ff);
  font-size: 16px;
  cursor: pointer;
}

.submit:hover {
  opacity: 0.7;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

/* //////////////// */
.purchase_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.purchase_button .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg);
  border: none;
  color: var(--ff);
  font-weight: 600;
  gap: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  font-size: 2rem;
  border-radius: 10px;
  padding: 10px;
}


.purchase_button .btn::before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  background: var(--ff);
  border-radius: 50%;
  left: -100%;
  top: 0;
  transition-duration: .3s;
  mix-blend-mode: difference;
}

.purchase_button .btn:hover::before {
  transition-duration: .3s;
  transform: translate(100%, -50%);
  border-radius: 0;
}

.purchase_button .btn:active {
  transform: translate(5px, 5px);
  transition-duration: .3s;
}

/* ///////////////////// */



@media (max-width:768px) {
  .form {
    max-width: 20rem;
    margin: 20% auto;
  }
}
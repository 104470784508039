.purchase_history {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    width: 90%;
    margin: 5rem auto;
    flex-wrap: wrap;
}

.purchase_history .purchase_item {
    display: flex;
    gap: 10px;
    cursor: pointer;
    border: 1px solid var(--gg);
    transition: all 0.3s ease-in-out;
    padding: 10px;
    border-radius: 10px;
    flex-wrap: wrap;
}

.purchase_history .purchase_item:hover {
    border: 1px solid var(--bg);
    border-bottom-width: 5px;
    border-right-width: 5px;
}

.purchase_history .purchase_item .img_picture {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
}

.purchase_history .purchase_item .img_picture img {
    transition: all 0.5s ease-in-out;
    height: 100%;
    object-fit: cover;
}

.purchase_history .purchase_item:hover .img_picture img {
    transform: scale(1.2);
}

.purchase_history .purchase_item .data {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.purchase_history .purchase_item .data p {
    border-bottom: 1px solid var(--bg);
    padding-bottom: 5px;
    font-weight: bold;
}

.purchase_history .purchase_item .data p.red_failed {
    color: red;
}

.purchase_history .purchase_item .data p.green_successful {
    color: green;
}

.purchase_history .purchase_item .price {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: auto;
}


.purchase_history .purchase_item .price button {
    background: var(--bg);
    color: var(--ff) !important;
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: fit-content;
    border-radius: 5px;
    margin-right: auto;
    cursor: pointer;
}
.user_view_tours {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1280px;
}

.user_view_tours .image_title {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    position: relative;
}

.user_view_tours .image_title img {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    object-fit: cover;
    object-position: center;
}


.user_view_tours .image_title h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--ff);
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #2928248c;
    padding: 10px 20px;
}

.user_view_tours .price_date {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}

.user_view_tours .price_date p span {
    font-weight: bold;
    font-size: 2rem;
    color: var(--ff);
    background: var(--bg);
    border-radius: 5px;
    padding: 5px;
}

.user_view_tours .price_date small {
    font-weight: bold;
    color: var(--ff);
    background: var(--bg);
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
}


@media (max-width:768px) {
    .user_view_tours .image_title h2 {
        font-size: 1rem;
        max-width: 90%;
    }

    .user_description {
        width: 90%;
        margin: auto;
    }
}
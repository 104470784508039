.view_purchase {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    gap: 10px;
}

.view_purchase .img_picture {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
}

.view_purchase .payment_information {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    gap: 10px;
}

.view_purchase .payment_information .div_payment_information {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--bg);
    padding: 5px 10px;
    border-radius: 5px;
    gap: 1vh;
}

.view_purchase .payment_information .div_payment_information button,
.view_purchase .payment_information .div_payment_information .use_ticket {
    width: fit-content;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--bg);
    color: var(--ff);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view_purchase_ref .payment_information .div_payment_information button:hover,
.view_purchase .payment_information .div_payment_information .use_ticket:hover {
    opacity: 0.7;
}

.view_purchase .payment_information .div_payment_information input[type="submit"] {
    width: fit-content;
}

.view_purchase .payment_information .div_payment_information input#invitation_mail {
    width: fit-content;
    padding: 10px;
    border: 1px solid var(--cc);
    border-radius: 5px;
}

form.div_payment_information .submit_use_ticket {
    display: flex;
    gap: 5px;
}
section.wrapper.notfound {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

section.wrapper.notfound .container {
    width: 100%;
    margin: 0 auto;
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

section.wrapper.notfound .container .scene {
    position: absolute;
    width: 100%;
    height: 90vh;
    vertical-align: middle;
}

section.wrapper.notfound .container .one,
section.wrapper.notfound .container .two,
section.wrapper.notfound .container .three,
section.wrapper.notfound .container .circle,
section.wrapper.notfound .container .p404 {
    width: 60%;
    height: 60%;
    top: 20% !important;
    left: 20% !important;
    min-width: 400px;
    min-height: 400px;
}

section.wrapper.notfound .container .one .content,
section.wrapper.notfound .container .two .content,
section.wrapper.notfound .container .three .content,
section.wrapper.notfound .container .circle .content,
section.wrapper.notfound .container .p404 .content {
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: content 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}

@keyframes content {
    0% {
        width: 0;
    }
}

section.wrapper.notfound .container .one .content .piece,
section.wrapper.notfound .container .two .content .piece,
section.wrapper.notfound .container .three .content .piece,
section.wrapper.notfound .container .circle .content .piece,
section.wrapper.notfound .container .p404 .content .piece {
    width: 200px;
    height: 80px;
    display: flex;
    position: absolute;
    border-radius: 80px;
    z-index: 1;
    animation: pieceLeft 8s cubic-bezier(1, 0.06, 0.25, 1) infinite both;
}

@keyframes pieceLeft {
    50% {
        left: 80%;
        width: 10%;
    }
}

@keyframes pieceRight {
    50% {
        right: 80%;
        width: 10%;
    }
}

@media screen and (max-width: 799px) {

    section.wrapper.notfound .container .one,
    section.wrapper.notfound .container .two,
    section.wrapper.notfound .container .three,
    section.wrapper.notfound .container .circle,
    section.wrapper.notfound .container .p404 {
        width: 90%;
        height: 90%;
        top: 5% !important;
        left: 5% !important;
        min-width: 280px;
        min-height: 280px;
    }
}

@media screen and (max-height: 660px) {

    section.wrapper.notfound .container .one,
    section.wrapper.notfound .container .two,
    section.wrapper.notfound .container .three,
    section.wrapper.notfound .container .circle,
    section.wrapper.notfound .container .p404 {
        min-width: 280px;
        min-height: 280px;
        width: 60%;
        height: 60%;
        top: 20% !important;
        left: 20% !important;
    }
}

section.wrapper.notfound .container .text {
    width: 60%;
    height: 40%;
    min-width: 400px;
    min-height: 500px;
    position: absolute;
    margin: 40px 0;
    animation: text 0.6s 1.8s ease backwards;
}

@keyframes text {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
}

@media screen and (max-width: 799px) {
    section.wrapper.notfound .container .text {
        min-height: 400px;
        height: 80%;
    }
}

section.wrapper.notfound .container .text article {
    width: 400px;
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 799px) {
    section.wrapper.notfound .container .text article {
        width: 100%;
    }
}

section.wrapper.notfound .container .text article p {
    color: white;
    font-size: 18px;
    margin-bottom: 40px;
    text-shadow: 6px 6px 10px #32243E;
}

section.wrapper.notfound .container .text article a {
    padding: 1rem 2rem;
    border-radius: 50px;
    cursor: pointer;
    z-index: 3;
    color: #695681;
    background-color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: all 0.3s ease;
}

section.wrapper.notfound .container .text article a:hover {
    transform: translateY(5px);
    background: #FB8A8A;
    color: white;
}

section.wrapper.notfound .container .p404 {
    font-size: 200px;
    font-weight: 700;
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    animation: anime404 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
    animation-delay: 1.2s;
}

@media screen and (max-width: 799px) {
    section.wrapper.notfound .container .p404 {
        font-size: 100px;
    }
}

@keyframes anime404 {
    0% {
        opacity: 0;
        transform: scale(10) skew(20deg, 20deg);
    }
}

section.wrapper.notfound .container .p404:nth-of-type(2) {
    color: #36184F;
    z-index: 1;
    animation-delay: 1s;
    filter: blur(10px);
    opacity: 0.8;
}

section.wrapper.notfound .container .circle {
    position: absolute;
}

section.wrapper.notfound .container .circle:before {
    content: "";
    position: absolute;
    width: 800px;
    height: 800px;
    background-color: rgba(54, 24, 79, 0.2);
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: circle 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}

@keyframes circle {
    0% {
        width: 0;
        height: 0;
    }
}

@media screen and (max-width: 799px) {
    section.wrapper.notfound .container .circle:before {
        width: 400px;
        height: 400px;
    }
}

section.wrapper.notfound .container .one .content:before {
    content: "";
    position: absolute;
    width: 600px;
    height: 600px;
    background-color: rgba(54, 24, 79, 0.3);
    border-radius: 100%;
    animation: circle 0.8s 0.4s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}

@media screen and (max-width: 799px) {
    section.wrapper.notfound .container .one .content:before {
        width: 300px;
        height: 300px;
    }
}

section.wrapper.notfound .container .one .content .piece {
    background: linear-gradient(90deg, #8077EA 13.7%, #EB73FF 94.65%);
}

section.wrapper.notfound .container .one .content .piece:nth-child(1) {
    right: 15%;
    top: 18%;
    height: 30px;
    width: 120px;
    animation-delay: 0.5s;
    animation-name: pieceRight;
}

section.wrapper.notfound .container .one .content .piece:nth-child(2) {
    left: 15%;
    top: 45%;
    width: 150px;
    height: 50px;
    animation-delay: 1s;
    animation-name: pieceLeft;
}

section.wrapper.notfound .container .one .content .piece:nth-child(3) {
    left: 10%;
    top: 75%;
    height: 20px;
    width: 70px;
    animation-delay: 1.5s;
    animation-name: pieceLeft;
}

section.wrapper.notfound .container .two .content .piece {
    background: linear-gradient(90deg, #FFEDC0 0%, #FF9D87 100%);
}

section.wrapper.notfound .container .two .content .piece:nth-child(1) {
    left: 0%;
    top: 25%;
    height: 40px;
    width: 120px;
    animation-delay: 2s;
    animation-name: pieceLeft;
}

section.wrapper.notfound .container .two .content .piece:nth-child(2) {
    right: 15%;
    top: 35%;
    width: 180px;
    height: 50px;
    animation-delay: 2.5s;
    animation-name: pieceRight;
}

section.wrapper.notfound .container .two .content .piece:nth-child(3) {
    right: 10%;
    top: 80%;
    height: 20px;
    width: 160px;
    animation-delay: 3s;
    animation-name: pieceRight;
}

section.wrapper.notfound .container .three .content .piece {
    background: #FB8A8A;
}

section.wrapper.notfound .container .three .content .piece:nth-child(1) {
    left: 25%;
    top: 35%;
    height: 20px;
    width: 80px;
    animation-name: pieceLeft;
    animation-delay: 3.5s;
}

section.wrapper.notfound .container .three .content .piece:nth-child(2) {
    right: 10%;
    top: 55%;
    width: 140px;
    height: 40px;
    animation-name: pieceRight;
    animation-delay: 4s;
}

section.wrapper.notfound .container .three .content .piece:nth-child(3) {
    left: 40%;
    top: 68%;
    height: 20px;
    width: 80px;
    animation-name: pieceLeft;
    animation-delay: 4.5s;
}
.view_purchase_full {
    width: 100%;
    height: 100%;
    min-height: 50vh;
}

.view_purchase_ref {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
}

.view_purchase_ref .payment_information {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    gap: 10px;
}

.view_purchase_ref .payment_information .div_payment_information {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--bg);
    padding: 5px 10px;
    border-radius: 5px;
    gap: 1vh;
}

.view_purchase_ref .payment_information .div_payment_information button {
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--bg);
    color: var(--ff);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view_purchase_ref .payment_information .div_payment_information button:hover {
    opacity: 0.7;
}
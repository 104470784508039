.admin_view_purchase {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    gap: 10px;
}

.admin_view_purchase .img_picture {
    width: 100%;
    max-height: 50vh;
    overflow: hidden;
}

.admin_view_purchase .payment_information {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    gap: 10px;
}
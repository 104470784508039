.contact .social_media {
    display: flex;
    flex-wrap: wrap;
}

.contact .social_media a {
    background: var(--cl);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    border-radius: 50%;
    color: var(--bg);
    padding: 0;
}

.contact .social_media a:hover {
    background: var(--bg);
    color: var(--cl);
}
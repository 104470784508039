.payment_status {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_status .payment_status_div {
    width: 100%;
    height: 20rem;
    max-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px var(--cc);
    border-radius: 8px;
    animation: payment_status 1s ease-in-out;
}


.payment_status .payment_status_div .message {
    width: 100%;
    height: 100%;
    background: var(--ff);
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    font-weight: 300;
    color: var(--bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding-top: 50px;
}

.payment_status .payment_status_div .message .check {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    color: var(--ff);
    font-size: 3.8rem;
    padding-top: 10px;
    border-radius: 50%;
    animation: payment_status_check 1.5s ease-in-out;
}

.payment_status .payment_status_div .message p {
    font-size: 1.1rem;
    padding: 0;
}

.payment_status .payment_status_div .message p:nth-child(2) {
    font-size: 1.3rem;
}


@keyframes payment_status_check {
    0% {
        transform: translate(-50%, 100%);
    }

    50% {
        transform: translate(-50%, 100%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes payment_status {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}
.viewUser {
    display: flex;
    justify-content: center;
    padding: 1rem;
    flex-wrap: wrap;
}

.User_item {
    background: var(--bg);
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.User_item p {
    margin-bottom: 10px;
    color: var(--ff);
    word-break: break-all;
    border-bottom: 2px solid var(--ff);
    font-size: 15px;
}

.User_item button {
    background: #f00;
    color: var(--ff);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    transition: .3s;
    font-size: 1.1rem;
    cursor: pointer;
}

.User_item button:hover {
    opacity: 0.7;
}

.form_search {
    margin: 1rem auto;
}
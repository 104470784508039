.payment_form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: payment_form 1s ease-in-out;
}

@keyframes payment_form {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

svg.IoCloseSharp {
    position: fixed;
    top: 1%;
    right: 1%;
    color: var(--ff);
    font-size: 2rem;
    cursor: pointer;
}

svg.IoCloseSharp:hover {
    opacity: 0.7;
}

.containt_payment_form {
    display: flex;
    gap: 1rem;
    overflow: hidden;
    overflow-y: auto;
    width: 90%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    padding: 3% 0;
    max-width: 1100px;
}

.containt_payment_form .containt_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: var(--ff);
    padding: 10px;
    border-radius: 1rem;
    width: 90%;
    gap: 1rem;
    margin: 0 auto;
}

.containt_payment_form .containt_info p {
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 5px solid var(--bg);
}

.containt_payment_form .containt_info small {
    font-weight: bold;
    color: var(--ff);
    background: var(--bg);
    border-radius: 5px;
    padding: 5px;
}

.containt_payment_form .ticket_price {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    direction: ltr;
    border-radius: 1rem;
    box-shadow: 0 0 5px var(--cc);
    border: none;
}

.containt_payment_form .containt_info .div_ticket_p {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 5px var(--cc);
}

.containt_payment_form .ticket_price .number_ticket {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 5px;
}

.containt_payment_form .ticket_price .number_ticket input#number_ticket {
    width: 5rem;
    height: 2rem;
    text-align: center;
    font-weight: bold;
    border-radius: 0;
}

.containt_payment_form .ticket_price .number_ticket .increment,
.containt_payment_form .ticket_price .number_ticket .dncrement {
    width: 2rem;
    height: 2rem;
    border-radius: 0;
    background: #111;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
}

.containt_payment_form .ticket_price .number_ticket .increment {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.containt_payment_form .ticket_price .number_ticket .dncrement {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.containt_payment_form .containt_info form.offer_discount {
    display: flex;
    align-items: flex-end;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 5px var(--cc);
    max-width: 20rem;
}

.containt_payment_form .containt_info form.offer_discount button {
    background: var(--bg);
    color: var(--ff);
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16px;
    height: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    white-space: nowrap;
    font-weight: bold;
}

.containt_payment_form .containt_info form.offer_discount button:hover {
    opacity: 0.7;
}

.containt_payment_form .containt_info form.offer_discount input[type="text"] {
    height: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* //////////////////////// */
form.credit_card {
    direction: ltr;
    background: var(--ff);
    padding: 10px;
    border-radius: 1rem;
    box-shadow: 0 0 5px var(--cc);
    flex: 1;
}

form.credit_card input {
    padding: 1em;
    margin: 0.5em 0;
    display: block;
}

form.credit_card .input-group {
    width: 100%;
    display: inline-block;
    position: relative;
}

form.credit_card .input-group input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
}

form.credit_card .input-group label {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

form.credit_card .input-group svg {
    position: absolute;
    right: 10px;
    bottom: 30%;
}

form.credit_card .multi-input {
    display: flex;
    gap: 10px;
    margin: auto;
}

form.credit_card small {
    color: red;
    font-size: 10px;
    display: block;
}

form.credit_card button {
    background: var(--bg);
    color: var(--ff);
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: auto;
    font-size: 20px;
}

form.credit_card button:hover {
    opacity: 0.7;
}

form.credit_card .multi-input .input-group:nth-child(1) {
    width: 70%;
}

form.credit_card .multi-input .input-group:nth-child(2) {
    width: 30%;
}

.containt_payment_form .form_credit_card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    background: var(--ff);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 5px var(--cc);
}

.containt_payment_form .form_credit_card .img_credit_card {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containt_payment_form .form_credit_card .img_credit_card img {
    width: 80%;
}

.containt_payment_form .form_credit_card_full {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ff);
    padding: 1rem;
    border-radius: 1rem;
    gap: 1rem;
}

@media (max-width: 750px) {
    .containt_payment_form .form_credit_card {
        flex-direction: column-reverse;
    }

    .containt_payment_form {
        padding: 10% 0;
    }
}
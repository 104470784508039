.viewTours {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    margin-bottom: 5rem;
}

.tours_item {
    background: var(--bg);
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.tours_item img {
    width: 90%;
    height: 18rem;
    object-fit: cover;
    margin: auto;
    margin-bottom: 1rem;
    border-radius: 1rem;
    border: 1px solid var(--cc);
}

.tours_item p {
    margin-bottom: 10px;
    color: var(--ff);
    word-break: break-all;
    border-bottom: 2px solid var(--ff);
    font-size: 15px;
}

.tours_item p span.span_price {
    display: inline-block;
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 5px;
}

.tours_item button {
    background: #f00;
    color: var(--ff);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    transition: .3s;
    font-size: 1.1rem;
    cursor: pointer;
}

.tours_item button:hover {
    opacity: 0.7;
}
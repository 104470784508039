.view_tours {
    display: flex;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
}

.view_tours .tours_item {
    background: var(--bg);
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 0;
    margin: 5px;
    width: 50%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-width: 280px;
}

.view_tours .tours_item:hover img {
    transform: scale(1.5);
}

.view_tours .tours_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 5px;
    border: 1px solid var(--cc);
    transition: all 0.5s ease-in-out;
}

.view_tours .tours_item p.tours_item_title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 10px;
    color: var(--ff);
    background: #11111163;
    word-break: break-all;
    border-bottom: none;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.5s ease-in-out;
}

.view_tours .tours_item:hover p.tours_item_title {
    transform: scale(1);
}

.view_tours .tours_item p span.span_price {
    display: inline-block;
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 5px;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.view_tours .tours_item button {
    background: var(--cc);
    color: var(--bg);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    transition: .3s;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: bold;
}

.view_tours .tours_item button:hover {
    opacity: 0.7;
}

.view_tours .tours_item p span.span_price span {
    font-weight: bold;
    font-size: 2rem;
    color: var(--cl);
}

@media (max-width: 750px) {
    .view_tours .tours_item {
        width: 95%;
        height: 10rem;
    }
}
.admin_discounts {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    margin-bottom: 5rem;
    flex-direction: column;
}

.form {
    margin: 5rem auto;
}

.discounts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.discounts .discount {
    background: var(--ff);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.discounts .discount p {
    background: var(--cc);
    padding: 3px 5px;
}

.discounts .discount button {
    background: var(--bg);
    color: var(--ff);
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.discounts .discount button:hover {
    opacity: 0.7;
}

.discounts .discount svg.closeSharp {
    cursor: pointer;
    color: #f00;
}

.full_discounts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
}

.full_discounts span {
    background: var(--bg);
    color: var(--ff);
    padding: 10px;
}

.discounts .discount h6 {
    display: flex;
    flex-direction: column;
}
.react-tel-input .country-list span {
    position: inherit;
}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 10px;
}

.direction.direction_rtl .form label span {
    left: auto;
    right: 10px;
}

.direction.direction_rtl .react-tel-input .form-control {
    padding-left: 0;
    margin-left: 0;
    padding-right: 48px;
    margin-right: 0;
    direction: ltr;
    text-align: end;
}

.direction.direction_rtl .react-tel-input {
    font-family: 'ExpoArabicBook', sans-serif !important;
    direction: rtl !important;
}

.direction.direction_rtl .react-tel-input .selected-flag {
    border-radius: 0 3px 3px 0 !important;
}

.direction.direction_rtl .react-tel-input .selected-flag .arrow {
    left: auto !important;
    right: 20px !important;
}

.direction.direction_rtl svg.faeye_show {
    position: absolute;
    left: 1rem;
    right: auto;
    top: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

svg.faeye_show {
    position: absolute;
    left: auto;
    right: 1rem;
    top: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

svg.faeye_show:hover {
    opacity: 0.7;
}


.activation_code_div div {
    display: flex;
    align-items: center;
    background: var(--bg);
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: fit-content;
    margin: auto;
    border-radius: 1rem;
    direction: ltr;
}

.activation_code_div div span {
    display: none;
}

.activation_code_div div input {
    font-size: 3rem;
    border-radius: 10px;
    padding: 5px;
    font-weight: bold;

    @media (max-width:768px) {
        font-size: 2rem;
    }
}
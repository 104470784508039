 .about {
     width: 100%;
     max-width: 1280px;
     height: 100%;
     margin: auto;
     padding: 1rem 0;
 }

 .about .data_description {
     display: flex;
     flex-direction: column;
     gap: 5px;
 }

 @media (max-width:768px) {
     .about .data_description {
         width: 90%;
         margin: auto;
     }
 }
#watch_tour {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    background: var(--ff);
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-rows: 50px 1fr;
    animation: watch_tour 1s ease-in-out;
}

@keyframes watch_tour {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

#watch_tour .top {
    display: flex;
    align-items: center;
    background: var(--bg);
    justify-content: space-between;
    padding: 0 1vh;
    border-bottom: 1px solid var(--ff);
}

#watch_tour .top svg.closeSharp {
    color: var(--ff);
    font-size: 2rem;
    cursor: pointer;
}

#watch_tour .top svg.closeSharp:hover {
    opacity: 0.7;
}

#watch_tour .top .time_watch {
    color: var(--ff);
}

#watch_tour .bottom {
    display: flex;
    align-items: center;
    background: var(--bg);
    justify-content: center;
}

#watch_tour .bottom iframe {
    width: 100%;
    height: 100%;
}
.add_tours_admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.add_tours_admin .div_picture {
    position: relative;
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    background: var(--bg);
    cursor: pointer;
}

.add_tours_admin input#picture {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.add_tours_admin form.add_tours_form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem;
    background: var(--cl);
    padding: 1rem;
    width: 90%;
}

.add_tours_admin input[type="submit"] {
    background: var(--bg);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.1rem;
    transition: all 0.5s ease-in-out 0s;
    width: fit-content;
}


.add_tours_admin input,
.add_tours_admin textarea {
    border: 1px solid var(--bg);
    font-size: 1rem;
    padding: 5px;
    border-radius: 10px;
    resize: none;
}

.add_tours_admin .data_form {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;
}

.add_tours_admin .data_form input {
    width: 40%;
    font-size: 1.5rem;
    padding: 10px;
}

.add_tours_admin .data_form .description {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
}


.add_tours_admin input[type="submit"] {
    background: var(--bg);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.1rem;
    transition: all 0.5s ease-in-out 0s;
    width: fit-content;
    grid-column: 2;
}

.ticket_price {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    padding: 10px;
    border: 1px solid var(--bg);
    border-radius: 1rem;
}

.ticket_price .duration_ticket {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.ticket_price .duration_ticket svg {
    font-size: 2rem;
    cursor: pointer;
}

.ticket_price .duration_ticket svg:hover {
    opacity: 0.7;
}

.ticket_price .duration_ticket input#duration_ticket {
    width: 5rem;
    font-size: 1.5rem;
    padding: 10px;
    text-align: center;
    border-radius: 0;
}

.ticket_price input#ticket_price {
    width: 10rem;
    font-size: 1.5rem;
    padding: 10px;
}

.jodit-react-container {
    width: 100%;
}

.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
    min-height: auto !important;
}

.jodit-status-bar {
    display: none;
}

span.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_about.jodit-ui-group__about {
    display: none !important;
}

@media (max-width: 1023px) {
    .add_tours_admin .data_form .description {
        width: 95%;
    }

    .add_tours_admin .data_form input {
        width: 95%;
    }
}
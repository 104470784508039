.social_media_admin {
    max-width: 20rem;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--bg);
}

.social_media_admin h2 {
    color: var(--gg);
    text-align: center;
}

.social_media_admin_form {
    margin-top: 20px;
}

.data_form {
    display: flex;
    flex-direction: column;
}

.description {
    margin-bottom: 15px;
}

label {
    color: var(--gg);
    font-weight: bold;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--cc);
    border-radius: 5px;
}

input[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--bg);
    color: var(--ff);
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
    opacity: 0.7;
}

.viewContact {
    display: flex;
    justify-content: center;
    padding: 1rem;
    flex-wrap: wrap;
}

.contact_item {
    background: var(--bg);
    border: 1px solid var(--cc);
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    width: 15rem;
}

.contact_item p {
    margin-bottom: 10px;
    color: var(--ff);
    word-break: break-all;
    border-bottom: 2px solid var(--ff);
    font-size: 15px;
}
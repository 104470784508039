.dashboard {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: auto;
    direction: ltr;

    .dashboard_deposit {
        display: flex;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 90%;
        gap: 1rem;

        .deposit_total {
            border: 1px solid var(--bg);
            border-radius: 1rem;
            padding: 1rem;
            flex: 1;
            text-align: right;

            svg {
                color: var(--bg);
                font-size: 2rem;
            }

            p {
                color: var(--bg);
                font-size: 1.5rem;
                font-weight: bold;
            }

            small {
                white-space: nowrap;
            }
        }
    }
}
.react-tel-input .country-list span {
    position: inherit;
}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 10px;
}

.direction.direction_rtl .form label span {
    left: auto;
    right: 10px;
}

.direction.direction_rtl .react-tel-input .form-control {
    padding-left: 0;
    margin-left: 0;
    padding-right: 48px;
    margin-right: 0;
    direction: ltr;
    text-align: end;
}

.direction.direction_rtl .react-tel-input {
    font-family: 'ExpoArabicBook', sans-serif !important;
    direction: rtl !important;
}

.direction.direction_rtl .react-tel-input .selected-flag {
    border-radius: 0 3px 3px 0 !important;
}

.direction.direction_rtl .react-tel-input .selected-flag .arrow {
    left: auto !important;
    right: 20px !important;
}

.direction.direction_rtl svg.faeye_show {
    position: absolute;
    left: 1rem;
    right: auto;
    top: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

svg.faeye_show {
    position: absolute;
    left: auto;
    right: 1rem;
    top: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

svg.faeye_show:hover {
    opacity: 0.7;
}

.form label h2.input {
    padding: 30px 10px 10px 10px !important;
}

.form label span.h2_span {
    top: 5px !important;
}

a.ticket_referral {
    background-color: var(--bg);
    padding: 10px;
    border-radius: 10px;
    color: var(--ff);
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
}

.InfoAccount .form a.ticket_referral {
    margin: auto;
}

@media (max-width:768px) {
    .form label h2.input {
        word-break: break-all;
        font-size: 1rem;
    }
}
div#contact {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    color: var(--ff);
    overflow: hidden;
}

div#contact .contact {
    width: 100%;
    max-width: 1280px;
    margin: 5% auto;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
}


div#contact .contact form.form_contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 25rem;
    box-shadow: 0 0 5px var(--bg);
    padding: 20px;
    border-radius: 20px;
    position: relative;
    margin: 2rem auto;
}

.contact form.form_contact {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.contact form.form_contact label {
    width: 100%;
    margin: auto;
    justify-content: center;
    flex-direction: row-reverse;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    background: var(--cl);
    border-radius: 5px;
    gap: 5px;
}

.contact form.form_contact input {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1.3rem;
    background: var(--cl);
    border: 1px solid var(--cc);
    border-radius: 5px;
}

.contact form.form_contact label svg {
    color: var(--bg);
    font-size: 1.3rem;
}

.contact form.form_contact button.submit {
    background-color: var(--bg);
    padding: 10px;
    border-radius: 10px;
    color: var(--ff);
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.contact form.form_contact textarea#message_contact {
    resize: none;
    width: 100%;
    min-height: 20vh;
    padding: 0.375rem 0.75rem;
    border-radius: 10px;
    font-size: 1.3rem;
    background: var(--cl);
}


@media (max-width:768px) {

    div#contact .contact {
        margin: 10% auto;
    }

    div#contact .contact h3 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    div#contact .contact p.contact-main-p {
        font-size: 14px;
    }

    div#contact .contact form.form_contact {
        max-width: 20rem;
    }


    .contact form.form_contact input {
        padding: 5px;
        font-size: 11px;
    }

    .contact form.form_contact textarea#message_contact {
        min-height: 20vh;
        padding: 5px;
        font-size: 11px;
    }

    .contact form.form_contact button.submit {
        font-size: 13px;
    }

}
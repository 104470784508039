nav {
    position: sticky;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    height: 10vh;
    background: var(--ff);
    padding: 0 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    box-shadow: 0px 4px 6px var(--bg);
    margin-bottom: 1rem;
    direction: ltr;
}

nav .logo {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: var(--bg)
}

nav .logo img {
    width: 12rem;
}

nav .links {
    float: right;
    padding: 0;
    margin: 0;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
}

nav .links li {
    cursor: pointer;
}

nav .links a {
    display: block;
    padding: 0.8em;
    font-size: 14px;
    font-weight: bold;
    color: var(--bg);
    text-decoration: none;
    position: relative;
    white-space: nowrap;
}

nav .links a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--bg);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

nav .links a:hover::before {
    visibility: visible;
    transform: scaleX(1);
    color: var(--bg);
}

#nav-toggle {
    position: absolute;
    top: -10vh;
}

nav .icon-burger {
    display: none;
    cursor: pointer;
}

nav .icon-burger .line {
    width: 30px;
    height: 5px;
    background: var(--bg);
    margin: 5px;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

nav .active {
    border-bottom: 5px solid var(--bg);
    color: var(--bg) !important;
}

nav a.logo.active {
    border-bottom: none;
}

nav a.logo {
    cursor: pointer;
}

nav a.ChangeLang {
    background: var(--bg);
    color: var(--ff) !important;
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: max-content;
    border-radius: 5px;
}

nav .social_media {
    display: none;
}


.full_links_page {
    position: relative;


    .sub_links_page {
        position: absolute;
        background: var(--ff);
        border-radius: 0 0 10px 10px;
        display: none;
        transition: all 0.5s ease-in-out;
        animation: sub_links_hide 0.5s ease-in-out;

        @media (max-width: 850px) {
            position: initial;
            display: flex !important;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
        }
    }


    li.links_page a {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

@keyframes sub_links_hide {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}




@media (max-width: 950px) {

    li.links_page {
        display: none;
    }

    nav {
        flex-direction: row;
        direction: rtl;
    }

    nav .logo {
        float: none;
        width: auto;
        justify-content: center;
    }

    nav .links {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 0;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        overflow: hidden;
        transition: all 1s ease-in-out;
        padding-top: 10%;
        background: var(--ff);
    }

    nav .links a {
        font-size: 16px;
        color: var(--bg);
        padding: 5px 1em;
    }


    nav :checked~.links {
        bottom: 0;
    }

    nav .icon-burger {
        display: block;
    }

    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    nav :checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }

    nav .logo img {
        width: 10rem;
    }

    nav .social_media {
        display: flex;
        flex-wrap: wrap;
    }

    nav .social_media a {
        background: var(--ff);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        border-radius: 50%;
        color: var(--bg);
        padding: 0;
    }

    nav .social_media a:hover {
        background: var(--cl);
    }
}